@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  background: "#7B61F3";
}

/* .gradient {
  border: linear-gradient(
    92.23deg,
    #406aff 21.43%,
    #3bace2 50.63%,
    #b936ee 100%,
    #ff56f6 117.04%
  );
} */

/* .gradient,
.active {
  background: linear-gradient(
    92.23deg,
    #406aff 21.43%,
    #3bace2 50.63%,
    #b936ee 100%,
    #ff56f6 117.04%
  );
} */

/* .active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
} */

/* .gradient-border::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  background: linear-gradient(45deg, #f3ec78, #af4261);
  border-radius: inherit;
} */

@layer base {
  body {
    @apply font-primary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-3xl mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium bg-violetGradient;
  }
  .btn-sm {
    @apply h-[48px] px-4 text-sm;
  }
  .btn-lg {
    @apply py-3 px-5 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  /* .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#42A6E3] to-[#FF56F6] font-bold font-primary hover:from-[#FF56F6] hover:to-[#42A6E3];
  } */
  .section {
    @apply py-8 lg:py-24 flex items-center;
  }
}
